<template>
  <div class="sort">
    <Row class="sort__wrapper"
         justify="between">
      <Column justify="center">
        <Row>
          <span class="sort__text">{{ $t('service.show_first') }}:</span>
          <span class="sort__type"
                :class="type === 'created_at' ? 'sort__type-active' : ''"
                v-on:click="changeType('created_at')">{{ $t('service.new') }}</span>
          <span class="sort__type"
                :class="type === 'avg' ? 'sort__type-active' : ''"
                v-on:click="changeType('avg')">{{ $t('service.popular') }}</span>
        </Row>
      </Column>
      <Column v-if="view !== 'cards-only'"
              justify="center">
        <Row>
          <Row align="center"
               class="sort__button"
               v-on:click.native="changeView('cards')">
            <Icon xlink="cards"
                  class="sort__icon"
                  :class="view === 'cards' ? 'sort__icon-active' : ''"
                  viewport="0 0 30 30"
                  :width="$mobile ? '20px' : '30px'"
                  :height="$mobile ? '20px' : '30px'"/>
            <span class="sort__view">{{ $t('service.cards') }}</span>
          </Row>
          <Row align="center"
               class="sort__button"
               v-on:click.native="changeView('map')">
            <Icon xlink="map"
                  class="sort__icon"
                  :class="view === 'map' ? 'sort__icon-active' : ''"
                  viewport="0 0 30 30"
                  :width="$mobile ? '20px' : '30px'"
                  :height="$mobile ? '20px' : '30px'"/>
            <span class="sort__view">{{ $t('service.on_map') }}</span>
          </Row>
        </Row>
      </Column>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'Sort',
  props: {
    type: {},
    view: {},
  },
  methods: {
    changeType(type) {
      this.$emit('change-sort-type', type);
    },
    changeView(type) {
      this.$emit('change-view-type', type);
    },
  },
};
</script>
